import React from "react";
import { Link, Script } from "gatsby";
import ConvertKit from "./convertkit-form";

const Layout = ({ location, title, children }) => {
  const rootPath = `/`;
  let header;

  if (location.pathname === rootPath) {
    header = (
      <Link
        className="shadow-none flex justify-between items-center border-b mb-5"
        to={`/`}
      >
        <h1 className="text-xl font-bold text-gray-800 flex items-center justify-center">
          <img
            src="/contentful-to-figma-logo.png"
            alt="Contentful to Figma plugin logo"
            width={56}
            height={56}
          />
          Contentful to Figma
        </h1>
        <span className="text-gray-700 block ml-3">Plugin for Figma</span>
      </Link>
    );
  } else {
    header = (
      <Link
        className="shadow-none flex justify-between items-center border-b mb-5"
        to={`/`}
      >
        <h3 className="text-xl font-bold text-gray-800 flex items-center justify-center">
          {title}
          <img
            src="/logo-final.png"
            alt="Contentful to Figma plugin logo"
            className="w-14"
          />
        </h3>
        <span className="text-gray-700 block ml-3">Plugin for Figma</span>
      </Link>
    );
  }
  return (
    <div className=" bg-gray-100 flex flex-col min-h-screen">
      <header className="max-w-2xl mx-auto px-5 pt-10 w-full">{header}</header>
      <main className="max-w-2xl mx-auto px-5">{children}</main>

      <footer className="bg-white mt-auto">
        <div className="flex max-w-2xl mx-auto px-5">
          <div className="px-4 py-5 sm:p-6 mx-auto max-w-xl">
            <h3 className="text-xl font-medium leading-6 text-gray-900">
              Sometimes I offer discounts, want to be notified?
            </h3>
            <ConvertKit />
          </div>
        </div>
        <div className="max-w-4xl mx-auto overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <div className="px-5 py-2">
              <a
                href="mailto:support@contentfultofigma.com"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Contact
              </a>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/blog/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Blog
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/about/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                About
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/buy/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Buy
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link
                to="/help/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Help
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link
                to="/terms-of-use/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Terms
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link
                to="/accessibility/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Accessibility
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link
                to="/privacy-policy/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Privacy
              </Link>
            </div>
          </nav>

          <p className="mt-8 text-center text-base text-gray-900">
            &copy; {new Date().getFullYear()} Khaled Garbaya All rights
            reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
