import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allContentfulBlogPost.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>No blog posts found.</p>
      </Layout>
    );
  }

  return (
    <Layout location={location} title={siteTitle}>
      <ol>
        {posts.map((post) => {
          const title = post.title;

          return (
            <li className="my-2 border-b p-2" key={post.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2 className="text-2xl font-black mb-1">
                    <Link
                      to={post.slug}
                      itemProp="url"
                      className="text-gray-900 hover:text-gray-700"
                    >
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.createdAt}</small>
                </header>
                <section>
                  <p
                    className="mt-2 prose dark:prose-invert"
                    dangerouslySetInnerHTML={{
                      __html: post.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          );
        })}
      </ol>
    </Layout>
  );
};

export default BlogIndex;

export const Head = () => <Seo title="Home" />;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      nodes {
        content {
          childMarkdownRemark {
            excerpt
          }
        }
        description
        slug
        title
        createdAt(formatString: "MMMMM DD, YYYY")
      }
    }
  }
`;
