import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({
  description = "",
  lang = "en",
  title = "",
  slug = "",
  ogImageUrl = "",
  type = "website",
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            defaultOgImage
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaUrl = `${site.siteMetadata.siteUrl}/${slug}`;
  const metaOgImage = ogImageUrl || site.siteMetadata.defaultOgImage;
  const metaType = type;
  // TODO - fix html lang
  return (
    <>
      <title>{`Contentful to Figma | ${title}`}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />

      <meta property="og:type" content={metaType} />
      {/* Twitter meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="contentfultofigma.com" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={metaOgImage} />
      <meta
        property="og:title"
        content="Contentful to Figma Plugin for Figma"
      />
      <meta property="og:site_name" content="Contentful to Figma" />
      <meta property="og:url" content={metaUrl} />

      <meta property="og:type" content="website" />
      {/* Facebook*/}
      <meta property="og:image" content={metaOgImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Linkedin */}
      <meta
        property="og:image"
        content={`${metaOgImage}?w=1200&h=627&fit=fill`}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta property="og:image:alt" content={title} />
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
