import React from "react";
import ConvertKitForm from "convertkit-react";

const ConvertKit = () => {
  return (
    <ConvertKitForm
      hideName={false}
      showLabels={true}
      formId={3917146}
      stacked={true}
      submitText="Subscribe"
      className="ck-form"
    />
  );
};

export default ConvertKit;
